import React from "react"
import { css } from "@emotion/core"
import Layout from "../../components/Layout"
import ProjectHero from "../../components/heros/ProjectHero"
import DoubleColumn from "../../components/projects/DoubleColumn"
import Download from "../../components/projects/Download"
import Logo from "../../components/projects/Logo"
import NextProject from "../../components/projects/NextProject"
import { Image } from "../../components/common"
import BannerDoubleColumn from "../../components/projects/BannerDoubleColumn"

import HeroImage from "../../images/projects/workn/hero.jpg"
import ProjectMobileImage from "../../images/projects/workn/project_mobile.png"
import ScreenshotImage from "../../images/projects/workn/screenshot.png"
import ColumnImage from "../../images/projects/workn/column.jpg"
import InstallImage from "../../images/projects/workn/install.png"
import LogoImage from "../../images/projects/workn/logo.png"

import NextImage from "../../images/projects/info/erste.jpg"

const Next = () => (
  <NextProject
    image={NextImage}
    title="Official application of Erste Liga"
    description="We developed the official application of the Erste Liga, which keeps us up-to-date with the status of the ice hockey tournament and players."
    to="erste"
  />
)

const Hero = () => (
  <ProjectHero
    title="WorkN"
    subtitle="The labour market has never been so organized and optimized. Welcome to
the third industrial revolution."
  />
)

const WorknProject = () => (
  <Layout withHero heroContent={Hero} heroImage={HeroImage} infoContent={Next}>
    <div
      css={theme => css`
        text-align: center;
        min-height: calc(
          100vh - ${theme.constants.infoFullHeight / 2}px -
            ${theme.constants.footerHeight}px -
            ${theme.constants.headerHeight}px
        );
        justify-content: center;
        display: flex;
        flex-direction: column;

        @media (max-width: 1000px) {
          position: relative;
          top: -100px;
        }

        @media (max-width: 650px) {
          position: relative;
          top: -130px;
        }
      `}
    >
      <div
        css={theme => css`
          margin: ${theme.constants.pageMargin};

          @media (max-width: 1200px) {
            margin: ${theme.constants.mediumpageMargin};
          }

          @media (max-width: 1000px) {
            margin: ${theme.constants.smallpageMargin};
          }
        `}
      >
        <DoubleColumn
          first
          title="Transformative workforce platform"
          description="WorkN is a transformative workforce platform for staffing firms and companies to work on-demand and do mobile scheduling"
          image={ProjectMobileImage}
          top={10}
          flip
          imageOverride={css`
            @media (min-width: 1920px) {
              height: 700px;
              padding: 0;
            }
          `}
        />
        <DoubleColumn
          title="More users every day"
          description="As a worker using WorkN you will see your shifts or assignments in real time, be able to check in and check out and review your employer more and more. If you are a contractor you will also get access to new work opportunities once you have been vetted as a trusted member in the market."
          image={ColumnImage}
          top={20}
          border={0}
          imageOverride={css`
            @media (min-width: 1920px) {
              height: 500px;
              padding: 0;
            }
          `}
        />
        <DoubleColumn
          title="On-demand marketplace to access local contractors"
          description="Built on the Serveture platform, companies can manage their staff with the most advanced shift management capabilities. This is, in turn, complements an unparalleled on-demand marketplace to access local contractors."
          image={ScreenshotImage}
          flip
          top={30}
          border={50}
          css={css`
            margin: 50px 0;
          `}
          imageOverride={css`
            @media (max-width: 650px) {
              height: 70vw;
            }

            @media (min-width: 1920px) {
              height: 500px;
              padding: 0;
            }
          `}
        />
      </div>
      <BannerDoubleColumn
        title='"Market Makers" '
        description='WorkN relies on “Market Makers" to onboard companies in a specific market and recruit, curate and vet contractors for those companies.'
        image={ProjectMobileImage}
        top={150}
        border={200}
        flip
        color="#e2a122"
        css={css`
          @media (max-width: 650px) {
            background-position: 0% 0%;
            background-size: 100% 550px;
          }

          @media (min-width: 1920px) {
            height: 700px;
            padding: 0;
          }
        `}
      />
      <div
        css={theme => css`
          margin: 0px 150px 0px 150px;

          @media (max-width: 1200px) {
            margin: ${theme.constants.mediumpageMargin};
          }

          @media (max-width: 1000px) {
            margin: ${theme.constants.smallpageMargin};
          }
          text-align: center;
        `}
      >
        <div
          css={theme => css`
            font-size: ${theme.constants.smallFontSize}px;
            font-weight: ${theme.constants.extraLight};
          `}
        >
          <p
            css={theme => css`
              font-size: ${theme.constants.mediumFontSize}px;
              font-weight: ${theme.constants.demi};
              margin-bottom: 30px;
            `}
          >
            Powered by Serveture
          </p>
          <p>
            A robust marketplace engine called Serveture sits behind WorkN
            managing the attributes, matching rules, geofencing, billings,
            reporting, reviews for each market maker and their respective
            companies and workers.
          </p>
        </div>
        <div
          css={css`
            margin: 50px auto;
          `}
        >
          <Download
            androidLink="https://play.google.com/store/apps/details?id=com.serveture.workn"
            iosLink="https://apps.apple.com/us/app/workn/id1083075642"
          />
        </div>
        <Image
          src={InstallImage}
          css={theme => css`
            width: 90%;

            @media (max-width: 1200px) {
              padding: ${theme.constants.mediumpageMargin};
            }

            @media (max-width: 1000px) {
              padding: ${theme.constants.smallpageMargin};
              margin-bottom: 100px;
            }
          `}
        />
      </div>
      <Logo
        src={LogoImage}
        css={css`
          height: 140px;
          margin: 0 auto;
          margin-bottom: 150px;

          @media (max-width: 650px) {
            height: 25vw;
            margin-bottom: 0;
          }
        `}
      />
    </div>
  </Layout>
)

export default WorknProject
