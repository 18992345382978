import React from "react"
import { css } from "@emotion/core"
import DoubleColumn from "./DoubleColumn"

const BannerDoubleColumn = ({
  image,
  title,
  description,
  top,
  border,
  flip,
  className,
  color,
  color2,
  footer,
  column1,
  column2,
  imageOverride,
}) => (
  <div
    css={css`
      background: linear-gradient(90deg, ${color}, ${color2 || color});
      background-position: 50%;
      background-size: 100% 60%;
      background-repeat: no-repeat;
      display: flex;
    `}
    className={className}
  >
    <DoubleColumn
      title={title}
      description={description}
      image={image}
      top={top}
      border={border}
      flip={flip}
      footer={footer}
      column1={column1}
      column2={column2}
      css={theme => css`
        color: ${theme.colors.white};
        margin: ${theme.constants.pageMargin};

        @media (max-width: 1200px) {
          margin: ${theme.constants.mediumpageMargin};
        }

        @media (max-width: 1000px) {
          margin: ${theme.constants.smallpageMargin};
        }
      `}
      imageOverride={imageOverride}
    />
  </div>
)

export default BannerDoubleColumn
